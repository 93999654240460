import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { Flex, FlexProps, Box, Text, useColorModeValue } from '@chakra-ui/react'

import { useAnimation, motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { moveDown, moveUp } from '@/lib/animations'
import FooterItems from './footer/footerItems'
import CustomButton from '../Button'
import { APP_NAME } from '@/lib/constants/appConstants'

export const Footer = (props: FlexProps) => {
  const router = useRouter()
  const cl = useColorModeValue('gray.800', 'white')
  const controls = useAnimation()
  const { inView, ref } = useInView()

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])

  return (
    <Flex mx="auto" flexDir={'column'} w="full">
      <Flex
        p="5rem 0 3rem 0"
        px={['1rem', '2rem', '4rem', '6rem']}
        mx="auto"
        w="full"
        {...props}
        as={motion.div}
        animate={controls}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={moveDown}
      >
        <FooterItems />
      </Flex>
      <Box as="div" pb="3rem" margin="0 auto" alignItems="center" px="3rem">
        <Flex
          fontSize="1.12rem"
          flexDir={['column-reverse', 'row']}
          textAlign="center"
        >
          <Box
            display="flex"
            textAlign="center"
            alignItems="center"
            justifyContent="center"
          >
            <Text mr="1rem">
              &copy; {APP_NAME} {new Date().getFullYear()}{' '}
            </Text>
            <Box as="span" display={['none', 'block']}>
              {' '}
              |
            </Box>
          </Box>

          <Box display="flex" textAlign="center" justifyContent="center">
            <Text mx="1rem">
              <CustomButton
                fontWeight="regular"
                h="1rem"
                backgroundColor="none"
                color={cl}
                _hover={{ color: 'primaryDark' }}
                _focus={{ boxShadow: 'none' }}
                background="none"
                fontSize="1rem"
                _active={{ background: 'none' }}
                onClick={() => router.push('/terms')}
              >
                Terms & Condition
              </CustomButton>
            </Text>{' '}
            <Text as="span" textAlign="center" display={['none', 'block']}>
              {' '}
              |
            </Text>
          </Box>
          <Box>
            <Text mx="1rem">
              <CustomButton
                fontWeight="regular"
                backgroundColor="none"
                color={cl}
                h="1rem"
                _hover={{ color: 'primaryDark' }}
                _focus={{ boxShadow: 'none' }}
                background="none"
                fontSize="1rem"
                _active={{ background: 'none' }}
                onClick={() => router.push('/privacy')}
              >
                {' '}
                Privacy
              </CustomButton>
            </Text>
          </Box>
        </Flex>
      </Box>
    </Flex>
  )
}
