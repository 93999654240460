import { VStack, Text } from '@chakra-ui/react'
import CustomButton from '@/components/Button'

export const CtaItems = (props: {
  tex: string
  btnText: string
  path: string
  onClick: () => void | any
}) => {
  const { tex, btnText, path, onClick } = props
  return (
    <VStack alignItems="center">
      <Text
        mb={6}
        fontWeight="medium"
        fontSize={{ base: '1rem', md: '1.75rem' }}
        px="0.5rem"
      >
        {tex}
      </Text>
      <CustomButton
        w="12rem"
        h="3rem"
        fontWeight="medium"
        borderRadius={'5px'}
        onClick={onClick}
        fontSize={{ base: '0.9rem', md: '1rem' }}
      >
        {btnText}
      </CustomButton>
    </VStack>
  )
}
