import router from 'next/router'
import { SIGN_IN, SIGN_UP } from '@/lib/constants/authConstants'
import { Container } from '../Container'
import { CtaItems } from './cta/ctaItems'
import { CTA_TEXT, CTA_BTN_TEXT, CTA_PATH } from '@/lib/constants/ctaConstants'

export const CTA = () => {
  const goToPage = (page: string) => {
    const host =
      process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
        ? process.env.NEXT_PUBLIC_APP_HOST
        : process.env.NEXT_PUBLIC_APP_LOCALHOST

    if (page === SIGN_IN) {
      return router.push(host + '/login')
    }
    return router.push(host + '/signup')
  }

  return (
    <Container
      flexDirection={['column']}
      width="full"
      h="18.5rem"
      maxH="18.5rem"
      bgColor="primaryRgba"
      justifyContent="center"
    >
      <CtaItems
        tex={CTA_TEXT}
        path={CTA_PATH}
        btnText={CTA_BTN_TEXT}
        onClick={() => goToPage(SIGN_UP)}
      />
    </Container>
  )
}
