import { Heading, Text, VStack, HStack, Flex } from '@chakra-ui/react'

const FooterTitles = (props: { title: string; description: string }) => {
  const { title, description } = props
  return (
    <Flex flexDir="column">
      <Heading
        as="h4"
        fontSize={['1.2rem', '1.3rem', '1.37rem']}
        mb="0.63rem"
        fontWeight="semibold"
      >
        {title}
      </Heading>
      <Text
        maxW="320px"
        fontSize={['1rem', '1rem', '1.125rem']}
        fontWeight="normal"
      >
        {description}
      </Text>
    </Flex>
  )
}

export default FooterTitles
