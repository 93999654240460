import { useEffect } from 'react'

import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import {
  Box,
  chakra,
  Flex,
  Grid,
  Heading,
  Icon,
  List,
  ListItem,
  Text,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react'

import {
  benefits,
  benefitsWithStart,
} from '@/lib/constants/benefitsTextConstants'
import LEColorConstants, {
  basicBgColorLeft,
  basicBgColorRight,
  basicTextColor,
} from '@/lib/constants/colorConstants'
import CustomImage from '@/components/Image'

import Title from './Title'
import { leftVarients, rightVarients } from '@/lib/animations'

const Benefits = () => {
  const controls = useAnimation()
  const { inView, ref } = useInView()

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])

  const { toggleColorMode: toggleMode, colorMode } = useColorMode()
  const borderColor = useColorModeValue('black', 'white')

  return (
    <Flex
      flexDir={'column'}
      mx="auto"
      py="6rem"
      px={['1rem', '3rem', '4rem', '10rem']}
      overflow="hidden"
      maxW="90rem"
    >
      <Flex
        width={'full'}
        flexWrap="wrap"
        columnGap={'3rem'}
        rowGap="4.4rem"
        mx="auto"
        pb="6rem"
        justifyContent={'center'}
      >
        {benefitsWithStart &&
          benefitsWithStart.length > 0 &&
          benefitsWithStart.map(item => (
            <Box
              key={item.id}
              display="flex"
              justifyContent={'center'}
              alignItems="center"
              flexDirection={'column'}
              w={['20rem', '17rem', '17rem', '17rem']}
              h="13rem"
              p="1rem"
              border={`2px solid ${LEColorConstants.primary}`}
              role="group"
              _hover={{
                backgroundColor: LEColorConstants.primary,
                transition: 'all .5s ease-in-out',
                borderRadius: '5px',
              }}
              textAlign={'center'}
            >
              <Icon
                as={item.icon}
                color={LEColorConstants.primary}
                _groupHover={{
                  color: LEColorConstants.white,
                }}
                mb="1rem"
                fontSize={'2rem'}
              />
              <Text
                fontSize={{ base: '1rem', md: '1.25rem' }}
                fontWeight={500}
                _groupHover={{
                  color: LEColorConstants.white,
                }}
              >
                {item.title}
              </Text>
            </Box>
          ))}
      </Flex>
      <Title />
      <chakra.section mx="auto" overflow={'hidden'} w="full">
        <Grid
          w="full"
          columnGap={['0.5rem', '0.7rem', '1rem', '1.5rem']}
          mx="auto"
          gap={['3rem', '0', '0', '0']}
          gridTemplateColumns={[
            '1fr',
            'repeat(2, 1fr)',
            'repeat(2, 1fr)',
            'repeat(2, 1fr)',
          ]}
          transition={'all 1s ease-in-out'}
        >
          {benefits &&
            benefits.length > 0 &&
            benefits.map((benefit, i) => (
              <Flex
                as={motion.div}
                animate={controls}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                key={i}
                maxW="28rem"
                mx="auto"
                justifyContent="center"
                alignItems="center"
                flexDir={'column'}
                p="1.5rem"
                color={basicTextColor[colorMode]}
                bg={
                  benefit.side === 'right'
                    ? basicBgColorLeft[colorMode]
                    : basicBgColorRight[colorMode]
                }
                borderRadius="30px"
                mt={['0', `${benefit?.mt}`, `${benefit?.mt}`, `${benefit?.mt}`]}
                mb={['0', `${benefit?.mb}`, `${benefit?.mb}`, `${benefit?.mb}`]}
                variants={
                  benefit.sideAnimation === 'left'
                    ? leftVarients
                    : rightVarients
                }
              >
                <Box
                  display={{ base: 'block', md: 'block' }}
                  mt={{ base: '2rem', md: '4rem' }}
                  mb="1rem"
                >
                  <CustomImage
                    src={benefit.src}
                    width={500}
                    height={231.26}
                    fill
                  />
                </Box>
                <Box>
                  <Heading
                    fontSize={{ base: '1.1rem', md: '1.5rem' }}
                    fontWeight="medium"
                    color="primary"
                    mt={{ base: '2rem', md: '0' }}
                  >
                    {benefit.title}
                  </Heading>
                  <Box
                    border={{ base: '1px', md: '2px' }}
                    borderColor=""
                    w={{ base: '3rem', md: '4.688rem' }}
                    mt="1.25rem"
                    mb={{ base: '2rem', md: '2.5rem' }}
                  ></Box>
                  <List
                    listStyleType="none"
                    color="primary"
                    listStyleImg="primary"
                    ml={{ base: '1.8rem', md: '2.813rem' }}
                  >
                    {benefit.text.map((el, i) => (
                      <ListItem
                        key={i}
                        fontSize={{ base: '0.8rem', md: '1rem' }}
                        fontWeight="normal"
                        lineHeight={{ base: '27px', md: '33px' }}
                        mb={{ base: '0.6rem', md: '1rem' }}
                        color={basicTextColor[colorMode]}
                        maxW="29.625rem"
                        _notLast={{ mb: '0.5.938rem' }}
                        _before={{
                          content: `"\\2022"`,
                          color: 'primary',
                          fontWeight: 'bold',
                          fontSize: { base: '1.5rem', md: '2rem' },
                          display: 'inline-block',
                          verticalAlign: 'middle',
                          w: { base: '1.1rem', md: '1.375rem' },
                          ml: '-1.375rem',
                        }}
                      >
                        {el}
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Flex>
            ))}
        </Grid>
      </chakra.section>
    </Flex>
  )
}

export default Benefits
