import router from 'next/router'
import React, { useEffect, useState } from 'react'

import {
  chakra,
  Flex,
  IconButton,
  useColorModeValue,
  useColorMode,
  Text,
  Heading,
  Box,
  Icon,
  keyframes,
  useDisclosure,
  Center,
} from '@chakra-ui/react'

import { BiPlay } from 'react-icons/bi'
import { SIGN_IN, SIGN_UP } from '@/lib/constants/authConstants'

import CustomImage from '@/components/Image/CustomImage'
import LEColorConstants, {
  basicTextColor,
  basicTextRgba,
} from '@/lib/constants/colorConstants'
import NavBar from '@/components/home/NavBar'
import { navMenus } from '@/lib/constants/navMenuConstants'
import CustomButton from '../Button'
import PlayVideo from '../VideoModal/PlayVideo'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { moveUp } from '@/lib/animations'

const animationKeyframes = keyframes`
  0% { transform: scale(1); }
  25% { transform: scale(1.2); }
  50% { transform: scale(1); }
  75% { transform: scale(1.2);}
  100% { transform: scale(1);}
`

const animation = `${animationKeyframes} 4s ease-in-out infinite`

type Menu = {
  name: string
  path: string
}

interface IProps {
  menus: Menu[]
  canStick: boolean
  triggerNav: boolean
  heroText: {
    heading: string
    paragraphFirst: string
    paragraphSecond: string
  }
}

export const Hero = ({ heroText, triggerNav }: IProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { toggleColorMode: toggleMode, colorMode } = useColorMode()
  const [video, setVideo] = useState<any>(false)

  const bg = useColorModeValue('white', 'black')
  const controls = useAnimation()
  const { inView, ref } = useInView()

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])

  const handlePlayVideo = () => {
    setVideo(true)
    onOpen()
  }

  const goToPage = (page: string) => {
    const host =
      process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
        ? process.env.NEXT_PUBLIC_APP_HOST
        : process.env.NEXT_PUBLIC_APP_LOCALHOST

    if (page === SIGN_IN) {
      return router.push(host + '/login')
    }
    return router.push(host + '/signup')
  }

  return (
    <chakra.div
      id="home"
      height={{ base: 'auto', md: 'auto' }}
      w="full"
      bg={bg}
      pb="4rem"
      transition="background 0.2s"
      overflowY="hidden"
      position="relative"
      bgImage={{
        base: "url('/top-circles.svg')",
        md: "url('/semi-circle.svg'), url('/top-circles.svg')",
      }}
      bgRepeat="no-repeat, no-repeat"
      bgSize="30%, 28%"
      bgPosition="center right, top left 45px"
      as={motion.div}
      animate={controls}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
    >
      <NavBar canStick={triggerNav} menus={navMenus} />
      <Flex
        mx="auto"
        px={['1rem', '3rem', '4rem', '6rem']}
        mt={{ base: '7rem', md: '8.5rem' }}
        alignItems="center"
        justifyContent="space-between"
        as={motion.div}
        variants={moveUp}
        columnGap="1rem"
        maxW="90rem"
        position={'relative'}
        h="full"
      >
        <Flex flexDir="column" flex={7}>
          <Flex
            flexDirection="column"
            flexWrap="wrap"
            alignItems={{ base: 'center', md: 'start' }}
          >
            <Heading
              as="h1"
              textAlign={{ base: 'center', md: 'start' }}
              fontSize={['1.5rem', '1.8rem', '2.3rem', '2.62rem']}
              fontWeight="semibold"
              color={basicTextColor[colorMode]}
              maxW={{ base: 'container.sm', md: '31.5rem' }}
              lineHeight={['35px', '45px', '63px']}
              mb="1.8rem"
            >
              {heroText.heading}
            </Heading>
            <Box
              maxW={{ base: 'container.sm', md: '35.75rem' }}
              textAlign={{ base: 'center', md: 'justify' }}
              fontSize={{ base: '1rem', md: '1.37rem' }}
              fontWeight={'medium'}
              color={basicTextRgba[colorMode]}
              mb="3.1rem"
            >
              <Text
                textAlign={{ base: 'center', md: 'left' }}
                color={LEColorConstants.primary}
              >
                {heroText.paragraphFirst}
              </Text>
              <Text
                textAlign={{ base: 'center', md: 'left' }}
                color={basicTextColor[colorMode]}
              >
                {heroText.paragraphSecond}
              </Text>
            </Box>
          </Flex>
          <Flex
            alignItems="center"
            justifyContent={{ base: 'center', md: 'start' }}
          >
            <CustomButton
              mr="1.81rem"
              w="12rem"
              h="3rem"
              borderRadius={'5px'}
              fontWeight="medium"
              onClick={() => goToPage(SIGN_UP)}
              fontSize={{ base: '0.8rem', md: '1rem' }}
            >
              Get Started
            </CustomButton>
            {/* <Flex
              w={{ base: '4.31rem', md: '5.31rem' }}
              h={{ base: '4.31rem', md: '5.31rem' }}
              border="1px"
              borderColor="primaryRgba"
              rounded="full"
            >
              <Flex
                mx="auto"
                my="auto"
                alignItems="center"
                justifyContent="center"
                w={{ base: '3.31rem', md: '4.37rem' }}
                h={{ base: '3.31rem', md: '4.37rem' }}
                border="1px"
                borderColor="primaryRgba"
                rounded="full"
                as={motion.div}
                animation={animation}
              >
                <Flex
                  mx="auto"
                  my="auto"
                  justifyContent="center"
                  alignItems="center"
                  w={{ base: '2.31rem', md: '3.43rem' }}
                  h={{ base: '2.31rem', md: '3.43rem' }}
                  border="1px"
                  borderColor="primaryRgba"
                  rounded="full"
                >
                  <Center>
                  <IconButton
                      // onClick={handlePlayVideo}
                      w={{ base: '2.31rem', md: '3.43rem' }}
                      h={{ base: '2.31rem', md: '3.43rem' }}
                      rounded="full"
                      aria-label="Play button"
                      bg={colorMode === 'light' ? 'primary' : 'primaryDark'}
                      color="white"
                      icon={<BiPlay size="1.8rem" />}
                      _hover={{ bg: 'primaryDark' }}
                      _active={{
                        bgColor: 'primaryLight',
                      }}
                    />
                  </Center>
                  {video && (
                    <PlayVideo
                      isOpen={isOpen}
                      onClose={onClose}
                      onClick={onClose}
                    />
                  )}
                </Flex>
              </Flex>
            </Flex> */}
          </Flex>
        </Flex>
        <Flex
          display={{ base: 'none', md: 'flex' }}
          flex={4}
          h="full"
          position={'relative'}
          justifyContent={'center'}
          alignItems="center"
        >
          <CustomImage
            position="absolute"
            src="/hero-illustrator.svg"
            top="50%"
            right="-15%"
            transform="translateY(-50%)"
            width={500}
            height={363.65}
            fill
          />
        </Flex>
      </Flex>
    </chakra.div>
  )
}
