import { stores } from '@/stores/index'
import LEColorConstants from './colorConstants'

export const pricingTitleHead: string = ' Plans & Pricing'
const config = stores.config.getUnits()

export const pricingCustomPlan: string = 'Having something different in mind?'

export const pricingTalkToUs: string = 'Talk to Us'
export const pricingTitle: string = 'PRICING'
export const pricingTitleSubscription: string =
  'No subscriptions, No recurring charges. Buy units only when you need them.'
export const pricingPackageTitle: string = 'Special Packages'

export const amountTitle: string = 'Amount'
export const unitTitle: string = 'Unit'
export const unitCalculator: string = 'Unit Calculator'
export const unitPurchaseTitle: string =
  'Purchasing Units outside the packages above,'
export const unitCostTitle: string = '1 Unit cost'
export const examChargingDemoTitle: string = ' Exam charging Demo per candidate'
export const PAYG: string = 'P.A.Y.G'
export const STANDARD: string = 'STANDARD'
export const totalCostTitle: string = 'Total Cost'
