import React, { useEffect, useState } from 'react'

import {
  VStack,
  Text,
  Box,
  Heading,
  Flex,
  useColorMode,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Icon,
  ListItem,
  List,
  ListIcon,
  Stack,
  Checkbox,
  useDisclosure,
} from '@chakra-ui/react'

import { useInView } from 'react-intersection-observer'
import { useAnimation, motion } from 'framer-motion'
import { stores } from '@/stores/index'

import {
  amountTitle,
  pricingCustomPlan,
  pricingPackageTitle,
  pricingTalkToUs,
  pricingTitle,
  pricingTitleSubscription,
  pricingTitleHead,
  unitTitle,
  unitCalculator,
  unitPurchaseTitle,
  unitCostTitle,
  examChargingDemoTitle,
  PAYG,
  STANDARD,
  totalCostTitle,
} from '@/lib/constants/pricingConstants'
import useForm from '@/hooks/useForm'

import CustomImage from '@/components/Image'
import CustomButton from '@/components/Button'
import LEColorConstants, {
  cardThemeColor,
  customTextLightColor,
  transparentBg,
} from '@/lib/constants/colorConstants'
import { TextInput } from '@/components/Input'
import { NUMBER_REGEX } from '@/lib/constants/regexConstants'
import { leftVarients, rightVarients } from '@/lib/animations'
import { BsCheck2Circle } from 'react-icons/bs'
import { GoTriangleDown } from 'react-icons/go'
import { ngnCurrencyFormatter } from '@/lib/helpers'
import HelpModal from '@/components/Help/HelpModal'

const inputData = [
  {
    id: 1,
    name: 'amount',
    type: 'number',
    required: true,
    pattern: NUMBER_REGEX,
    errorMessage: 'Please enter a valid amount or unit',
  },
]

const featuresAvailable = [
  {
    id: 1,
    name: 'Live Monitoring',
    value: 1,
    checked: false,
  },
  {
    id: 2,
    name: 'Email Notification',
    value: 0,
    checked: false,
  },
  {
    id: 3,
    name: 'Video Playback',
    value: 0,
    checked: false,
  },
]

export const PricingContainer = () => {
  const config = stores.config.getUnits()

  const pricing = [
    {
      id: 1,
      title: 'P.A.Y.G',
      type: 'plan',
      img: '/assets/price1.svg',
      width: '82.79px',
      height: '80px',
      color: LEColorConstants.primary,
      features: [
        'One off usage',
        'Candidate only eligible to take 1 exam',
        `Charges ${config.payg_unit_per_exam} Unit per candidate`,
      ],
    },
    {
      id: 2,
    },
    {
      id: 3,
      title: 'STANDARD',
      type: 'plan',
      img: '/assets/price2.svg',
      width: '82.79px',
      height: '80px',
      color: LEColorConstants.secondary,
      features: [
        'Multiple times usage per month',
        'Candidates can take up to 10 exams (+3 free if the 10 has been exhausted) per month',
        `Charges ${config.standard_unit_per_exam} Units per candidate per month`,
      ],
    },
  ]

  const unitPrice: number = config.unit_rate_to_ngn

  const nigerianCurrencyFormat = (val: any) =>
    new Intl.NumberFormat('en-NG', {
      style: 'currency',
      currency: 'NGN',
    }).format(val)

  const [unit, setUnit] = useState<any>(amountTitle)
  const [value, setValues] = useState<any>()
  const [examDemo, setExamDemo] = useState<string>(PAYG)
  const [featureSelect, setFeatureSelect] = useState<any[]>([
    ...featuresAvailable,
  ])

  const [costOfUnitPerCandidate, setCostOfUnitPerCandidate] = useState<number>(
    config.payg_unit_per_exam
  )

  useEffect(() => {
    if (examDemo === PAYG) {
      setCostOfUnitPerCandidate(config.payg_unit_per_exam)
    } else {
      setCostOfUnitPerCandidate(config.standard_unit_per_exam)
    }
  }, [examDemo])

  const { onOpen, isOpen, onClose } = useDisclosure()
  const [serviceCost, setServiceCost] = useState<any>(unitTitle)
  const [talkToUs, setTalkToUs] = useState<boolean>(false)

  const { colorMode } = useColorMode()

  const handleTalkToUs = () => {
    setTalkToUs(true)
    onOpen()
  }

  const moneyToUnit = Number(value) / unitPrice
  const unitToMoney = Number(value) * unitPrice

  const controls = useAnimation()
  const { inView, ref } = useInView()

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])

  const handleChangeUnit = (e: any) => {
    setUnit(e.target.textContent)
  }

  const handleChangeExamDemo = (e: any) => {
    setExamDemo(e.target.textContent)
  }

  const handleChangeServiceCost = (e: any) => {
    setServiceCost(e.target.textContent)
  }

  const defaultValue: number = 0

  const toggleFeatureSelect = (e: any, item: any) => {
    if (e.target.checked) {
      setFeatureSelect(
        featureSelect.map(d => (d.id === item.id ? { ...d, checked: true } : d))
      )
    } else {
      // remove from list
      setFeatureSelect(
        featureSelect.map(d =>
          d.id === item.id ? { ...d, checked: false } : d
        )
      )
    }
  }

  const featureSelectedValue = featureSelect
    ?.filter(item => item.checked === true)
    .map((item: any) => item.value)
  const sumFeatureSelectedValue = featureSelectedValue.reduce(
    (previousValue, currentValue) => previousValue + currentValue,
    0
  )

  let totalCost = costOfUnitPerCandidate
  if (serviceCost === unitTitle) {
    totalCost = costOfUnitPerCandidate + sumFeatureSelectedValue
  } else {
    totalCost = (costOfUnitPerCandidate + sumFeatureSelectedValue) * unitPrice
  }

  const { handleChange, inputTypes, handleSubmit, errors } = useForm({
    inputs: inputData,
    cb: async inputs => {},
  })

  return (
    <VStack
      backgroundImage={transparentBg[colorMode]}
      backgroundSize="cover"
      backgroundPosition={'center'}
      backgroundRepeat="no-repeat"
      overflowX={'hidden'}
      w="full"
      m=" 0 auto"
      py="4rem"
    >
      <Box mb={{ base: '2rem', md: '3rem' }}>
        <Text
          textAlign="center"
          fontSize={['1.5rem', '2.12rem']}
          fontWeight={600}
        >
          {pricingTitleHead}
        </Text>
        <Text fontSize={'1rem'}>You are being charged per candidate</Text>
      </Box>
      <VStack w="full" px="1rem" overflow={'hidden'}>
        <Flex
          flexDir={['column', 'row', 'row', 'row']}
          alignItems="end"
          justifyContent="center"
          mb="2.8rem"
          rowGap={'2rem'}
          columnGap="1rem"
          as={motion.div}
          animate={controls}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
        >
          {pricing &&
            pricing.length > 1 &&
            pricing.map((el, i) => (
              <Flex
                key={`pricing_session${el.id}`}
                maxW="24.75rem"
                as={motion.div}
                justifyContent="center"
                alignItems={'center'}
                variants={el.title === 'P.A.Y.G' ? leftVarients : rightVarients}
              >
                {el.type === 'plan' ? (
                  <Box
                    border="1px solid"
                    borderColor={el.color}
                    px="2rem"
                    py="1.5rem"
                    maxW={{ base: '23rem', md: '24.75rem' }}
                    minHeight={'23.68rem'}
                    transition=".5s ease-in-out"
                    display={['block', 'block', 'block', 'block']}
                    alignItems="center"
                    _notLast={{
                      mr: { base: '0', md: '2rem' },
                    }}
                    _notFirst={{
                      ml: { base: '0', md: '2rem' },
                    }}
                  >
                    <CustomImage
                      src={el.img}
                      height={el.height}
                      width={el.width}
                      alt="illustrator"
                      mr={['1.563rem', '1.563rem', '0', '0']}
                    />
                    <Box>
                      <Heading
                        as="h3"
                        color={el.color}
                        fontSize={{ base: '1rem', md: '1.5rem' }}
                        fontWeight={600}
                        lineHeight="36px"
                        mb="1.25rem"
                      >
                        {el.title}
                      </Heading>
                      <List spacing={3} alignItems="center">
                        {el.features.map(feat => (
                          <ListItem
                            fontSize="0.875rem"
                            fontWeight="normal"
                            lineHeight="21px"
                            alignItems={'center'}
                          >
                            <ListIcon
                              as={BsCheck2Circle}
                              color={el.color}
                              fontSize="1.5rem"
                            />
                            {feat}
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                  </Box>
                ) : (
                  <Box display={['none', 'none', 'none', 'block']}>
                    <svg
                      width="11"
                      height="380"
                      viewBox="0 0 11 346"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.2"
                        d="M5.5 0.666667C2.55448 0.666666 0.166667 3.05448 0.166667 6C0.166666 8.94552 2.55448 11.3333 5.5 11.3333C8.44552 11.3333 10.8333 8.94552 10.8333 6C10.8333 3.05448 8.44552 0.666667 5.5 0.666667ZM5.49999 334.667C2.55447 334.667 0.166652 337.054 0.166652 340C0.166652 342.946 2.55447 345.333 5.49999 345.333C8.4455 345.333 10.8333 342.946 10.8333 340C10.8333 337.054 8.4455 334.667 5.49999 334.667ZM4.5 6L4.49999 340L6.49999 340L6.5 6L4.5 6Z"
                        fill="#1B1C1E"
                      />
                    </svg>
                  </Box>
                )}
              </Flex>
            ))}
        </Flex>
        <VStack
          justifyContent="center"
          h="auto"
          display={'flex'}
          flexDir="column"
          w="full"
        >
          <Flex flexDir={'column'}>
            <Text
              fontSize="1.1rem"
              fontWeight={500}
              lineHeight="27px"
              maxW="20.9rem"
              textAlign={'center'}
              mb="1rem"
            >
              {pricingCustomPlan}
            </Text>
            <Box textAlign={'center'}>
              <CustomButton
                maxW="9.8rem"
                maxH={'2.75rem'}
                padding="10px 40px"
                fontSize={'1rem'}
                fontWeight="medium"
                borderRadius={'5px'}
                lineHeight="24px"
                mb="4rem"
                onClick={handleTalkToUs}
              >
                {pricingTalkToUs}

                {talkToUs && (
                  <HelpModal
                    isOpen={isOpen}
                    onClose={onClose}
                    onClick={onClose}
                  />
                )}
              </CustomButton>
            </Box>
          </Flex>
          <Flex maxW="64rem" justifyContent={'center'} flexDir="column">
            <Flex
              w={['full', 'lg', '2xl', '64rem']}
              bgColor={cardThemeColor[colorMode]}
              p={{ base: '0.5rem', md: '2rem' }}
              border="3px solid #F2F2F2"
              flexDir={'column'}
            >
              <Flex alignItems="center" flexDir={'column'} px="1.5rem">
                <Text
                  fontSize={{ base: '1.2rem', md: '1.5rem' }}
                  fontWeight={600}
                  lineHeight="36px"
                  color={LEColorConstants.primary}
                  mb="0.6rem"
                >
                  {pricingTitle}
                </Text>
                <Text
                  maxW="19.5rem"
                  textAlign={'center'}
                  lineHeight="24px"
                  color={customTextLightColor[colorMode]}
                  opacity={0.6}
                  mb="4rem"
                >
                  {pricingTitleSubscription}
                </Text>
              </Flex>

              <Text fontSize={'1.125rem'} fontWeight={600} mb="1rem">
                {pricingPackageTitle}
              </Text>
              {config.special_packages &&
                config.special_packages.length > 0 &&
                config.special_packages.map(el => (
                  <Flex
                    key={`pricingPackages_${el.unit}`}
                    border={'2px solid #F2F2F2'}
                    fontSize={{ base: '0.8rem', md: '1.125rem' }}
                    lineHeight={'30px'}
                    p="0.5rem"
                    justifyContent={'space-between'}
                    mb="1rem"
                  >
                    <Text fontWeight={500}>{el.unit} Units</Text>{' '}
                    <Text color={LEColorConstants.primary} fontWeight={600}>
                      {ngnCurrencyFormatter(el.amount_in_ngn)}
                    </Text>
                  </Flex>
                ))}

              <Flex
                mt="3rem"
                border="1px solid #E5F2FD"
                boxShadow={' 0px 7px 35px 2px rgba(0, 0, 0, 0.1)'}
                flexDir="column"
                p={{ base: '2rem 1rem', md: '1rem 2rem' }}
                borderRadius="10px"
              >
                <Text
                  fontSize={{ base: '1rem', md: '1.1rem' }}
                  fontWeight={500}
                  mb="1.12rem"
                >
                  {unitPurchaseTitle}{' '}
                  <Text as="span" color={LEColorConstants.secondary}>
                    {unitCostTitle} {ngnCurrencyFormatter(unitPrice)}
                  </Text>
                </Text>
                <Text
                  fontSize={{ base: '1rem', md: '1.25rem' }}
                  fontWeight={600}
                  mb="0.7rem"
                >
                  {unitCalculator}
                </Text>

                <Flex
                  justifyContent={'space-between'}
                  flexDir={['column', 'row', 'row', 'row']}
                >
                  <Box display={'flex'} flex={1} flexDir="column">
                    <Box>
                      <Menu>
                        <MenuButton alignItems={'center'}>
                          {unit === amountTitle
                            ? `${amountTitle}(N)`
                            : unitTitle}{' '}
                          <Icon fontSize={'0.7rem'} as={GoTriangleDown} />
                        </MenuButton>
                        <MenuList onClick={e => handleChangeUnit(e)}>
                          <MenuItem>{amountTitle}</MenuItem>
                          <MenuItem>{unitTitle}</MenuItem>
                        </MenuList>
                      </Menu>
                    </Box>
                    <form action="post">
                      {inputData.map(data => (
                        <TextInput
                          key={`input_${data.id}`}
                          type={data.type}
                          name={data.name}
                          value={inputTypes[data.name]}
                          handleChange={val => {
                            let event: any = {}
                            event.target = {
                              name: data.name,
                              value: val.target.value,
                            }
                            handleChange(event)
                            setValues(val.target.value)
                          }}
                          error={errors[data.name] && data.errorMessage}
                          TextInputProps={{
                            _focusWithin: {
                              border:
                                !errors[data.name] &&
                                `2px solid ${LEColorConstants.primary}`,
                              borderRadius: '10px',
                            },
                          }}
                        />
                      ))}
                    </form>
                  </Box>
                  <Box
                    fontSize={'2rem'}
                    fontWeight={600}
                    m="1.2rem"
                    display={['none', 'block', 'block', 'block']}
                  >
                    =
                  </Box>

                  <Box
                    display={'flex'}
                    flex={1}
                    flexDir="column"
                    opacity={1}
                    mt={['1.5rem', '0', '0', '0']}
                  >
                    <Text>
                      {unit === amountTitle ? unitTitle : `${amountTitle}(N)`}:
                    </Text>
                    <TextInput
                      type="number"
                      value={unit === amountTitle ? moneyToUnit : unitToMoney}
                      handleChange={handleChange}
                      TextInputProps={{
                        w: 'full',
                        fontWeight: 'bold',
                        isReadOnly: true,
                      }}
                    />
                  </Box>
                </Flex>
              </Flex>
              <Flex
                w="full"
                border={`1px solid ${LEColorConstants.secondary}`}
                p={{ base: '1rem 1rem', md: '2rem' }}
                flexDir={'column'}
                mt="2rem"
                mb="2rem"
              >
                <Flex fontSize={{ base: '1rem', md: '1.25rem' }} mb="2rem">
                  <Text mr="0.5rem" color={LEColorConstants.secondary}>
                    {examChargingDemoTitle}{' '}
                  </Text>{' '}
                  <Menu>
                    <MenuButton
                      fontSize={{ base: '0.9rem', md: '1.1rem' }}
                      fontWeight="medium"
                    >
                      {examDemo}{' '}
                      <Icon fontSize={'0.7rem'} as={GoTriangleDown} />
                    </MenuButton>
                    <MenuList
                      fontSize={'1rem'}
                      onClick={e => handleChangeExamDemo(e)}
                    >
                      <MenuItem fontSize={{ base: '0.8rem', md: '1rem' }}>
                        {PAYG}
                      </MenuItem>
                      <MenuItem fontSize={{ base: '0.8rem', md: '1rem' }}>
                        {STANDARD}
                      </MenuItem>
                    </MenuList>
                  </Menu>{' '}
                </Flex>
                <Flex flexDir={'column'}>
                  <Flex
                    justifyContent={'space-between'}
                    fontSize={{ base: '1rem', md: '1.1rem' }}
                    fontWeight={'medium'}
                    mb="0.8rem"
                  >
                    <Text>Service</Text>
                    <Flex>
                      <Text>Cost ({serviceCost})</Text>
                      <Menu>
                        <MenuButton>
                          <Icon fontSize={'0.7rem'} as={GoTriangleDown} />
                        </MenuButton>
                        <MenuList onClick={e => handleChangeServiceCost(e)}>
                          <MenuItem fontSize={{ base: '0.8rem', md: '1rem' }}>
                            {unitTitle}
                          </MenuItem>
                          <MenuItem fontSize={{ base: '0.8rem', md: '1rem' }}>
                            {amountTitle}
                          </MenuItem>
                        </MenuList>
                      </Menu>{' '}
                    </Flex>
                  </Flex>
                  <Flex
                    justifyContent={'space-between'}
                    fontSize={{ base: '1rem', md: '1.1rem' }}
                    fontWeight={'medium'}
                    mb="0.8rem"
                  >
                    <Text>Exam</Text>
                    <Text>
                      {serviceCost === unitTitle
                        ? `${costOfUnitPerCandidate.toFixed(2)} Unit`
                        : nigerianCurrencyFormat(
                            (costOfUnitPerCandidate * unitPrice).toFixed(2)
                          )}
                    </Text>
                  </Flex>
                  <Stack spacing={[1, 5]} direction={'column'}>
                    {featureSelect &&
                      featureSelect.length > 0 &&
                      featureSelect.map((item, index) => (
                        <Flex
                          justifyContent="space-between"
                          key={item.id}
                          fontSize="1rem"
                        >
                          {' '}
                          <Checkbox
                            name={item.name}
                            colorScheme={'brand.primary'}
                            onChange={(e: any) => toggleFeatureSelect(e, item)}
                            type="checkboxx"
                            value={item.value}
                            checked={item.checked}
                          >
                            <Text opacity={item.checked ? 1 : 0.6}>
                              {item.name}{' '}
                            </Text>
                          </Checkbox>
                          <Text opacity={item.checked ? 1 : 0.6}>
                            {item.checked
                              ? serviceCost === unitTitle
                                ? `${item.value.toFixed(2)} Unit`
                                : nigerianCurrencyFormat(
                                    (item.value * unitPrice).toFixed(2)
                                  )
                              : defaultValue.toFixed(2)}
                          </Text>
                        </Flex>
                      ))}
                  </Stack>
                  <Flex
                    justifyContent={'space-between'}
                    fontSize={{ base: '1rem', md: '1.25rem' }}
                    fontWeight={600}
                    mt="2.5rem"
                  >
                    <Text>{totalCostTitle}</Text>
                    <Text>
                      {serviceCost === unitTitle
                        ? `${totalCost.toFixed(2)} Units`
                        : nigerianCurrencyFormat(totalCost.toFixed(2))}
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </VStack>
      </VStack>
    </VStack>
  )
}
