import NextLink from 'next/link'
import { HStack, Stack, Image, Link, Box } from '@chakra-ui/react'
import CustomImage from '@/components/Image'
import { CustomLink } from '@/components/Link'

type Icon = {
  src: string
  path: string
}

interface IProps {
  icons: Icon[]
}

const FooterIconsList = ({ icons }: IProps) => {
  return (
    <Stack direction="row">
      {icons &&
        icons.length > 1 &&
        icons.map(item => (
          <Box
            key={`foter_icon_${item.src}`}
            width="29px"
            height="29px"
            borderRadius="3px"
            pt="0.4rem"
            bg="secondaryLighter"
            _hover={{
              transform: 'scale(1.2) translateY(4px)',
              transition: 'all 0.3s ease',
            }}
            display="flex"
            justifyContent="center"
            verticalAlign="center"
          >
            <CustomLink href={item.path}>
              <CustomImage width="20.2px" height="20.2px" src={item.src} />
            </CustomLink>
          </Box>
        ))}
    </Stack>
  )
}

export default FooterIconsList
