import NextLink from 'next/link'
import React from 'react'
import {
  VStack,
  useColorModeValue,
  Link,
  List,
  ListItem,
} from '@chakra-ui/react'
import { Link as ScrollLink } from 'react-scroll'

type Menu = {
  id: string
  name: string
  path: string
}

interface IProps {
  menus: Menu[]
}

const FooterList = ({ menus }: IProps) => {
  const cl = useColorModeValue('gray.800', 'white')
  return (
    <VStack alignItems={['center', 'start']}>
      {menus &&
        menus.length > 0 &&
        menus.map(menu => (
          <List
            key={menu.name}
            color={cl}
            display="inline-block"
            alignItems="flex-start"
            fontSize={{ base: '1rem', md: '1.13rem' }}
            fontWeight="normal"
            _focus={{ boxShadow: 'none' }}
            _hover={{
              transform: 'translate(10px)',
              transition: 'all 0.3s ease',
            }}
          >
            <ListItem role="group">
              <NextLink href={menu.path}>
                <Link
                  mb="0.63rem"
                  _groupHover={{
                    color: 'brand.primary.500',
                    textDecoration: 'none',
                  }}
                  isExternal
                >
                  <ScrollLink
                    to={menu.id}
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={500}
                  >
                    {menu.name}
                  </ScrollLink>
                </Link>
              </NextLink>
            </ListItem>
          </List>
        ))}
    </VStack>
  )
}

export default FooterList
