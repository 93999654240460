import { useEffect, useState } from 'react'
import { SECTION_TITLE } from '@/lib/constants/benefitsTextConstants'
import { basicTextColor } from '@/lib/constants/colorConstants'
import { Box, Flex, Text, useColorMode } from '@chakra-ui/react'

const Title = () => {
  const { toggleColorMode: toggleMode, colorMode } = useColorMode()

  const textArray = ['Easy.', 'Convenient.', 'Fast.']

  const [currentSlide, setCurrentSlide] = useState(0)

  const textCount = textArray.length

  useEffect(() => {
    const changeSlide = setInterval(() => nextSlide(), 3000)
    return () => clearInterval(changeSlide)
  }, [])

  const nextSlide = () => {
    setCurrentSlide(s => (s === textCount - 1 ? 0 : s + 1))
  }

  const carouselStyle = {
    transition: 'all .5s ease-in-out',
    mt: `-${currentSlide * 26}%`,
  }

  return (
    <Flex
      justifyContent="center"
      w="full"
      my="3.188rem"
      id="benefits"
      mx="auto"
      textAlign={'center'}
      alignItems="center"
      fontSize={{ base: '1.2rem', md: '1.8rem' }}
      fontWeight="semibold"
      color={basicTextColor[colorMode]}
    >
      <Box
        mr="0.5rem"
        h={{ base: '2rem', md: '3rem' }}
        justifyContent={'center'}
        alignItems="center"
      >
        <Text>{`${SECTION_TITLE}`}</Text>
      </Box>
      <Box
        overflow="hidden"
        h={{ base: '2rem', md: '3rem' }}
        alignItems={'center'}
      >
        <Box overflow={'hidden'} textAlign="left" {...carouselStyle}>
          {textArray &&
            textArray.length > 0 &&
            textArray.map(el => (
              <Text
                color="primary"
                key={`testElemet_${el}`}
                justifyContent={'center'}
                textAlign="left"
                alignItems={'center'}
              >
                {el}
              </Text>
            ))}
        </Box>
      </Box>
    </Flex>
  )
}

export default Title
