import { Flex, VStack, Heading, Text, Box } from '@chakra-ui/react'
import FooterList from './footerList'
import { footerNav } from '@/lib/constants/navMenuConstants'
import FooterIconsList from './footerIconsList'
import { footerIcons } from '@/lib/constants/footerIcons'
import FooterTitles from './footerTitles'
import { APP_DESCRIPTION, APP_NAME } from '@/lib/constants/appConstants'
const FooterItems = () => {
  return (
    <Flex
      w="container.lg"
      mx="auto"
      columnGap={[2, 4, 6, 14]}
      rowGap={10}
      justifyContent="space-between"
      flexDir={['column', 'row']}
      alignItems={['center', 'start']}
      textAlign={['center', 'start']}
    >
      <Flex>
        <FooterTitles title={APP_NAME} description={APP_DESCRIPTION} />
      </Flex>
      <Flex>
        <VStack alignItems={['center', 'start']}>
          <Text
            fontSize={['1rem', '1.1rem', '1.25rem']}
            fontWeight="semibold"
            mb="1.19rem"
          >
            Social media
          </Text>
          <FooterIconsList icons={footerIcons} />
        </VStack>
      </Flex>
      <Flex>
        <VStack alignItems={['center', 'start']}>
          <Heading
            fontSize={['1rem', '1.1rem', '1.37rem']}
            fontWeight="semibold"
            mb={2}
          >
            Quick Links
          </Heading>
          <VStack>
            <FooterList menus={footerNav} />
          </VStack>
        </VStack>
      </Flex>
    </Flex>
  )
}

export default FooterItems
