import { useEffect, useState } from 'react'
import Head from 'next/head'
import { chakra } from '@chakra-ui/react'
// @ts-ignore
import TawkTo from 'tawkto-react'

import { Hero } from '@/components/home/Hero'
import { CTA } from '@/components/home/CTA'
import { NewsLetter } from '@/components/home/NewsLetter'
import { Footer } from '@/components/home/Footer'
import { navMenus } from '@/lib/constants/navMenuConstants'
import { heroHeading } from '@/lib/constants/heroTextConstants'
import { Testimonial } from '@/components/home/Testimonial'
import { Pricing } from '@/components/home/Pricing'
import FAQ from '@/components/home/FAQ'
import Benefits from '@/components/home/benefits/Benefits'
import FeatureSupport from '@/components/home/FeatureSupport'
import { APP_NAME } from '@/lib/constants/appConstants'

const MAX_VIEWPORT: number = 10

const Index = () => {
  const [triggerNav, setTriggerNav] = useState<boolean>(false)

  const handleScroll = () => {
    const currentScrollY = window.scrollY
    if (currentScrollY > MAX_VIEWPORT) {
      setTriggerNav(false)
    }

    if (currentScrollY < MAX_VIEWPORT) {
      setTriggerNav(true)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [triggerNav])

  useEffect(() => {
    new TawkTo(
      process.env.NEXT_PUBLIC_TAWKTO_PROPERTY_ID,
      process.env.NEXT_PUBLIC_TAWKTO_WIDGET_ID
    )
  }, [])

  return (
    <>
      <Head>
        <title>{APP_NAME}</title>
        <meta
          name="description"
          content="Assess to thousands of applicants through our multi-platform assessment system."
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest"></link>
      </Head>
      <chakra.main>
        <Hero
          triggerNav={triggerNav}
          canStick={triggerNav}
          menus={navMenus}
          heroText={heroHeading}
        />
        <Benefits />
        <CTA />
        <Pricing />
        <FeatureSupport />
        <FAQ />
        {/* <Testimonial /> */}
        <NewsLetter />
        <CTA />
        <Footer />
      </chakra.main>
    </>
  )
}

export default Index
