import { chakra } from '@chakra-ui/react'
import { PricingContainer } from '@/components/home/pricing/PricingContainer'

export const Pricing = () => {
  return (
    <chakra.section w="full" mb="5.25rem" mt="2rem" id="pricing">
      <PricingContainer />
    </chakra.section>
  )
}
